.code,
code {
  background: rgba(135, 131, 120, 0.15);
  border-radius: 3px;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 85%;
  tab-size: 2;
}

code {
  color: #eb5757;
}

.code {
  padding: 1.5em 1em;
}

.code-wrap {
  white-space: pre-wrap;
  word-break: break-all;
}

.code > code {
  background: none;
  padding: 0;
  font-size: 100%;
  color: inherit;
}

blockquote {
  font-size: 1.25em;
  margin: 1em 0;
  padding-left: 1em;
  border-left: 3px solid rgb(55, 53, 47);
}

.bookmark {
  text-decoration: none;
  max-height: 8em;
  padding: 0;
  display: flex;
  width: 100%;
  align-items: stretch;
}

.bookmark-title {
  font-size: 0.85em;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.75em;
  white-space: nowrap;
}

.bookmark-text {
  display: flex;
  flex-direction: column;
}

.bookmark-info {
  flex: 4 1 180px;
  padding: 12px 14px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bookmark-image {
  width: 33%;
  flex: 1 1 180px;
  display: block;
  position: relative;
  object-fit: cover;
  border-radius: 1px;
}

.bookmark-description {
  color: rgba(55, 53, 47, 0.6);
  font-size: 0.75em;
  overflow: hidden;
  max-height: 4.5em;
  word-break: break-word;
}

.bookmark-href {
  font-size: 0.75em;
  margin-top: 0.25em;
}

/* .sans {
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji",
    "Segoe UI Symbol";
} */
.code {
  font-family: "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono",
    Courier, monospace;
}
.serif {
  font-family: Lyon-Text, Georgia, ui-serif, serif;
}
.mono {
  font-family: iawriter-mono, Nitti, Menlo, Courier, monospace;
}
.pdf .sans {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
    "Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
    "Noto Sans CJK JP";
}
.pdf:lang(zh-CN) .sans {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
    "Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
    "Noto Sans CJK SC";
}
.pdf:lang(zh-TW) .sans {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
    "Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
    "Noto Sans CJK TC";
}
.pdf:lang(ko-KR) .sans {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
    "Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
    "Noto Sans CJK KR";
}
.pdf .code {
  font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
    "Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
    "Noto Sans Mono CJK JP";
}
.pdf:lang(zh-CN) .code {
  font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
    "Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
    "Noto Sans Mono CJK SC";
}
.pdf:lang(zh-TW) .code {
  font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
    "Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
    "Noto Sans Mono CJK TC";
}
.pdf:lang(ko-KR) .code {
  font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
    "Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
    "Noto Sans Mono CJK KR";
}
.pdf .serif {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
    "Noto Color Emoji", "Noto Serif CJK JP";
}
.pdf:lang(zh-CN) .serif {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
    "Noto Color Emoji", "Noto Serif CJK SC";
}
.pdf:lang(zh-TW) .serif {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
    "Noto Color Emoji", "Noto Serif CJK TC";
}
.pdf:lang(ko-KR) .serif {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
    "Noto Color Emoji", "Noto Serif CJK KR";
}
.pdf .mono {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    "Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK JP";
}
.pdf:lang(zh-CN) .mono {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    "Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK SC";
}
.pdf:lang(zh-TW) .mono {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    "Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK TC";
}
.pdf:lang(ko-KR) .mono {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    "Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK KR";
}
.highlight-default {
  color: rgba(55, 53, 47, 1);
}
.highlight-gray {
  color: rgba(120, 119, 116, 1);
  fill: rgba(120, 119, 116, 1);
}
.highlight-brown {
  color: rgba(159, 107, 83, 1);
  fill: rgba(159, 107, 83, 1);
}
.highlight-orange {
  color: rgba(217, 115, 13, 1);
  fill: rgba(217, 115, 13, 1);
}
.highlight-yellow {
  color: rgba(203, 145, 47, 1);
  fill: rgba(203, 145, 47, 1);
}
.highlight-teal {
  color: rgba(68, 131, 97, 1);
  fill: rgba(68, 131, 97, 1);
}
.highlight-blue {
  color: rgba(51, 126, 169, 1);
  fill: rgba(51, 126, 169, 1);
}
.highlight-purple {
  color: rgba(144, 101, 176, 1);
  fill: rgba(144, 101, 176, 1);
}
.highlight-pink {
  color: rgba(193, 76, 138, 1);
  fill: rgba(193, 76, 138, 1);
}
.highlight-red {
  color: rgba(212, 76, 71, 1);
  fill: rgba(212, 76, 71, 1);
}
.highlight-gray_background {
  background: rgba(241, 241, 239, 1);
}
.highlight-brown_background {
  background: rgba(244, 238, 238, 1);
}
.highlight-orange_background {
  background: rgba(251, 236, 221, 1);
}
.highlight-yellow_background {
  background: rgba(251, 243, 219, 1);
}
.highlight-teal_background {
  background: rgba(237, 243, 236, 1);
}
.highlight-blue_background {
  background: rgba(231, 243, 248, 1);
}
.highlight-purple_background {
  background: rgba(244, 240, 247, 0.8);
}
.highlight-pink_background {
  background: rgba(249, 238, 243, 0.8);
}
.highlight-red_background {
  background: rgba(253, 235, 236, 1);
}
.block-color-default {
  color: inherit;
  fill: inherit;
}
.block-color-gray {
  color: rgba(120, 119, 116, 1);
  fill: rgba(120, 119, 116, 1);
}
.block-color-brown {
  color: rgba(159, 107, 83, 1);
  fill: rgba(159, 107, 83, 1);
}
.block-color-orange {
  color: rgba(217, 115, 13, 1);
  fill: rgba(217, 115, 13, 1);
}
.block-color-yellow {
  color: rgba(203, 145, 47, 1);
  fill: rgba(203, 145, 47, 1);
}
.block-color-teal {
  color: rgba(68, 131, 97, 1);
  fill: rgba(68, 131, 97, 1);
}
.block-color-blue {
  color: rgba(51, 126, 169, 1);
  fill: rgba(51, 126, 169, 1);
}
.block-color-purple {
  color: rgba(144, 101, 176, 1);
  fill: rgba(144, 101, 176, 1);
}
.block-color-pink {
  color: rgba(193, 76, 138, 1);
  fill: rgba(193, 76, 138, 1);
}
.block-color-red {
  color: rgba(212, 76, 71, 1);
  fill: rgba(212, 76, 71, 1);
}
.block-color-gray_background {
  background: rgba(241, 241, 239, 1);
}
.block-color-brown_background {
  background: rgba(244, 238, 238, 1);
}
.block-color-orange_background {
  background: rgba(251, 236, 221, 1);
}
.block-color-yellow_background {
  background: rgba(251, 243, 219, 1);
}
.block-color-teal_background {
  background: rgba(237, 243, 236, 1);
}
.block-color-blue_background {
  background: rgba(231, 243, 248, 1);
}
.block-color-purple_background {
  background: rgba(244, 240, 247, 0.8);
}
.block-color-pink_background {
  background: rgba(249, 238, 243, 0.8);
}
.block-color-red_background {
  background: rgba(253, 235, 236, 1);
}
.select-value-color-pink {
  background-color: rgba(245, 224, 233, 1);
}
.select-value-color-purple {
  background-color: rgba(232, 222, 238, 1);
}
.select-value-color-green {
  background-color: rgba(219, 237, 219, 1);
}
.select-value-color-gray {
  background-color: rgba(227, 226, 224, 1);
}
.select-value-color-opaquegray {
  background-color: rgba(255, 255, 255, 0.0375);
}
.select-value-color-orange {
  background-color: rgba(250, 222, 201, 1);
}
.select-value-color-brown {
  background-color: rgba(238, 224, 218, 1);
}
.select-value-color-red {
  background-color: rgba(255, 226, 221, 1);
}
.select-value-color-yellow {
  background-color: rgba(253, 236, 200, 1);
}
.select-value-color-blue {
  background-color: rgba(211, 229, 239, 1);
}

.checkbox {
  display: inline-flex;
  vertical-align: text-bottom;
  width: 16;
  height: 16;
  background-size: 16px;
  margin-left: 2px;
  margin-right: 5px;
}

.checkbox-on {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%2358A9D7%22%2F%3E%0A%3Cpath%20d%3D%22M6.71429%2012.2852L14%204.9995L12.7143%203.71436L6.71429%209.71378L3.28571%206.2831L2%207.57092L6.71429%2012.2852Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
}

.checkbox-off {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.75%22%20y%3D%220.75%22%20width%3D%2214.5%22%20height%3D%2214.5%22%20fill%3D%22white%22%20stroke%3D%22%2336352F%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E");
}
