.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
}

@media only screen and (min-width: 700px) {
  .container {
    max-width: 700px;
  }
}



body {
  /* font-family: 'Rubik', sans-serif; */
  font-family: "Merriweather", serif;
  line-height: 28px;
}
h1 {
  margin-top: 50px;
  text-transform: uppercase;
}

.simple-table {
	margin-top: 1em;
  	margin-bottom: 1em;

	font-size: 0.875rem;
	empty-cells: show;
}
.simple-table td {
	height: 29px;
	min-width: 120px;
}

.simple-table th {
	height: 29px;
	min-width: 120px;
}

.simple-table-header-color {
	background: rgb(247, 246, 243);
	color: black;
}
.simple-table-header {
	font-weight: 500;
}


h1,
h3,
h4,
h5 {
  font-family: "Merriweather", serif;
  font-weight: 900;
}

h3,
h4,
h5 {
  text-transform: capitalize;
}

button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.me {
  border-radius: 50%;
  width: 150px;
  margin-left: 50px;
  margin-right: 50px;
  border: 5px solid #29a09a;
  margin-top: 10px;
}

.persona {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.resume {
  display: flex;
  margin-top: 50px;
  column-gap: 50px;
}

@media only screen and (max-width: 700px) {
  .resume {
    flex-wrap: wrap;
  }
}

.masonry-container {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
}

@media only screen and (min-width: 700px) {
  .masonry-container {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media only screen and (min-width: 1000px) {
  .masonry-container {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}

.masonry-container {
  margin-top: 50px;

  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
}

.masonry-item {
  display: inline-block;
  width: 100%;
  /* border: 1px solid #d4d4d4; */
  border-radius: 6px;
  margin-bottom: 15px;
  position: relative;
}

.masonry-header {
  position: absolute;
  bottom: 0;

  width: 100%;

  align-items: center;
  background-color: rgba(107, 107, 107, 0.5);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  /* border-bottom: 1px solid #d4d4d4; */
}

.masonry-title {
  font-weight: 600;
  margin: 5px 10px 5px 10px;
  flex-grow: 1;
}
.masonry-description {
  margin: 5px 10px 5px 10px;
  font-size: 10px;
}

.masonry-item img {
  display: block;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  /* padding: 10px; */
}

.badge {
  margin-right: 5px;
  vertical-align: text-bottom;
}

.social {
  margin: 5px;
  color: #29a09a;
}

.social:hover {
  color: #269590;
}

.footer {
  padding: 10px 50px;
}

.repice-title {
  font-weight: 600;
}

.repice-description {
  color: #242424;
  margin-bottom: 10px;
}

/* .masonry-item {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
} */

.masonry-header {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.item-img {
  box-shadow: 2px 2px 5px rgba(51, 51, 51, 0.35);
  transition: box-shadow 0.3s ease-in-out;
}

.item-img:hover {
  box-shadow: 2px 2px 5px rgba(51, 51, 51, 0.5);
}

/* .masonry-item .item-img:hover {
  -webkit-filter: brightness(120%); filter: brightness(120%); 
} */

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #269590;
}

nav {
  padding-bottom: 20px;
}

.menu {
  background-color: white;
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding-top: 20px;
}
.menu li {
  float: left;
  margin-right: 10px;
  padding-bottom: 2px;
}

li a.active {
  color: #269590;
  border-bottom: 3px solid #269590;
}

.story {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 50px;
}

.story-inner {
  font-size: 18px;
}

.modal-pic {
  width: 60%;
  margin-top: 30px;
  box-shadow: 3px 3px 4px #a9a9a9;
}

.blogpost-date {
  font-size: 12px;
  margin: 20px 0 0 0;
}

.divider {
  width: 100%;
  border-bottom: 1px solid #a7a7a7;
  margin: 20px 0px 20px 0px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.post-cover {
  height: 250px;
  width: 100%;
  object-fit: cover;
  margin: 20px 0 25px 0;
}
